<template>
  <div>
    <div class="grid">
      <WidgetHeading
        v-if="title"
        class="title"
        is-big
        is-first
        :content="title"
      >
        <template #right>
          <Filters
            :query-key="FILTER_KEY"
            :options="
              availableGalleries.map(option =>
                option.value === defaultGallery
                  ? { ...option, value: undefined }
                  : option
              )
            "
          />
        </template>
      </WidgetHeading>
    </div>
    <div class="grid tiles">
      <div
        v-for="number in Math.ceil((images.length ?? 1) / 15)"
        :key="number"
        :style="{
          '--galleries-before': number - 1
        }"
        class="backgrounds"
      >
        <OutlineText
          :text="selectedGalleryName"
          class="background-1 background"
        />
        <OutlineText
          :text="selectedGalleryName"
          class="background-2 background"
        />
      </div>
      <button
        v-for="(image, index) in images"
        :id="index === activeImageIndex ? 'popup-trigger' : undefined"
        :key="`${image.id}+${index}`"
        class="tile"
        type="button"
        :aria-label="t('enlarge-image')"
        @click="activeImageIndex = image.id === -1 ? null : index"
      >
        <BaseImage
          :image="image.image"
          v-bind="
            getImageSizes({
              xs: { w: 249, h: 163 },
              md: { w: 254, h: 164 },
              lg: { w: 440, h: 325 }
            })
          "
        />
        <BaseImage
          class="hover-image"
          :image="image.image"
          v-bind="
            getImageSizes({
              xs: { w: 249 * 1.74, h: 163 * 1.74 },
              md: { w: 254 * 1.74, h: 164 * 1.74 },
              lg: { w: 440 * 1.74, h: 325 * 1.74 }
            })
          "
        />
      </button>
      <div ref="bottom" />
      <Lightbox
        v-if="images"
        v-model:active-image-index="activeImageIndex"
        :widget-id="widgetId"
        :images="images"
        :images-count="imagesCount"
        @close="closeLightbox"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  widgetId: string
  title: string
  defaultGallery: string
  availableGalleries: { label: string; value: string }[]
}>()

const { t } = useI18n()

const bottom = ref<HTMLElement>()

const FILTER_KEY = 'gallery'
const route = useRoute()

const selectedGallery = computed(
  () => flatten(route.query[FILTER_KEY]) || props.defaultGallery
)

const {
  items: images,
  itemsCount: imagesCount,
  fetchAll
} = useInfiniteScroll(
  'gallery',
  bottom,
  page =>
    useApi().publicGalleriesGalleryImagesList({
      page,
      gallery: [selectedGallery.value],
      page_size: 15
    }),
  {
    watch: [selectedGallery],
    skeleton: {
      id: -1,
      gallery: selectedGallery.value,
      sort_value: 0,
      image: {
        id: 'image_id',
        alt: 'skeleton',
        title: 'skeleton',
        height: 10,
        width: 10,
        file: '/images/placeholder.svg',
        images: '',
        ppoi_tuple: [0.5, 0.5] as unknown as string
      }
    }
  }
)

const activeImageIndex = ref<null | number>(null)

watch(activeImageIndex, index => {
  if (index !== null) fetchAll()
})

const selectedGalleryName = computed(
  () =>
    props.availableGalleries.find(
      ({ value }) => value === selectedGallery.value
    )?.label ?? ''
)

const closeLightbox = () => {
  document.getElementById('popup-trigger')?.focus()
  activeImageIndex.value = null
}
</script>

<style lang="scss" scoped>
.title {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );

  margin-bottom: rem(16px);

  @include media-up(md) {
    margin-bottom: rem(24px);
  }
}

.tiles {
  position: relative;
}

.backgrounds {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 16
    )
  );
  position: absolute;
  top: calc(var(--galleries-before) * rem(1909px));

  @include media-up(md) {
    top: calc(var(--galleries-before) * rem(1081px));
  }

  @include media-up(lg) {
    top: calc(var(--galleries-before) * rem(2058px));
  }
}

.background {
  position: absolute;
  width: 100vw;

  &-1 {
    top: rem(257px);
    transform: rotate(11.03deg);

    @include media-up(md) {
      top: rem(53px);
      transform: rotate(11.163deg);
    }

    @include media-up(lg) {
      top: rem(121px);
    }
  }

  &-2 {
    top: rem(1276px);
    transform: rotate(-25.427deg);

    @include media-up(md) {
      top: rem(616px);
      transform: rotate(-20.409deg);
    }

    @include media-up(lg) {
      top: rem(1013px);
    }
  }
}

.tile {
  @include focus-visible;
  position: relative;
  height: rem(163px);
  margin-bottom: rem(10px);
  cursor: pointer;

  @include media-down(md) {
    &:nth-child(15n + 1 of .tile) {
      @include column(xs, 3, 1);

      .hover-image {
        transform: scale(1.343);
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 2 of .tile) {
      @include column(xs, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 3 of .tile) {
      @include column(xs, 2, 2);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 4 of .tile) {
      @include column(xs, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 5 of .tile) {
      @include column(xs, 3, 1);

      .hover-image {
        transform: scale(1.343);
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 6 of .tile) {
      @include column(xs, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 7 of .tile) {
      @include column(xs, 2, 2);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 8 of .tile) {
      @include column(xs, 2, 2);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 9 of .tile) {
      @include column(xs, 3);

      .hover-image {
        transform: scale(1.343);
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 10 of .tile) {
      @include column(xs, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 11 of .tile) {
      @include column(xs, 2, 2);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 12 of .tile) {
      @include column(xs, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 13 of .tile) {
      @include column(xs, 3, 1);

      .hover-image {
        transform: scale(1.343);
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 14 of .tile) {
      @include column(xs, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 15 of .tile) {
      @include column(xs, 2, 2);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }
  }

  @include media-up(md) {
    height: rem(164px);
    margin-bottom: rem(16px);

    &:nth-child(15n + 1 of .tile) {
      @include column(md, 3, 1);

      .hover-image {
        transform-origin: 48% 50%;
      }
    }

    &:nth-child(15n + 2 of .tile) {
      @include column(md, 2, 4);
    }

    &:nth-child(15n + 3 of .tile) {
      @include column(md, 2, 6);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 4 of .tile) {
      @include column(md, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 5 of .tile) {
      @include column(md, 3, 4);

      .hover-image {
        transform-origin: 52.5% 50%;
      }
    }

    &:nth-child(15n + 6 of .tile) {
      @include column(md, 2, 1);
    }

    &:nth-child(15n + 7 of .tile) {
      @include column(md, 2, 3);
    }

    &:nth-child(15n + 8 of .tile) {
      @include column(md, 2, 6);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 9 of .tile) {
      @include column(md, 3);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 10 of .tile) {
      @include column(md, 2, 3);
    }

    &:nth-child(15n + 11 of .tile) {
      @include column(md, 2, 5);
    }

    &:nth-child(15n + 12 of .tile) {
      @include column(md, 2);

      .hover-image {
        transform-origin: 0 50%;
      }
    }

    &:nth-child(15n + 13 of .tile) {
      @include column(md, 3, 5);

      .hover-image {
        transform-origin: 100% 50%;
      }
    }

    &:nth-child(15n + 14 of .tile) {
      @include column(md, 2, 1);
    }

    &:nth-child(15n + 15 of .tile) {
      @include column(md, 2, 3);
    }
  }

  @include media-up(lg) {
    height: rem(325px);
    margin-bottom: rem(20px);

    &:nth-child(15n + 1 of .tile) {
      @include column(lg, 4, 2);
    }

    &:nth-child(15n + 2 of .tile) {
      @include column(lg, 3, 6);
    }

    &:nth-child(15n + 3 of .tile) {
      @include column(lg, 3, 9);
    }

    &:nth-child(15n + 4 of .tile) {
      @include column(lg, 3);
    }

    &:nth-child(15n + 5 of .tile) {
      @include column(lg, 4, 6);
    }

    &:nth-child(15n + 6 of .tile) {
      @include column(lg, 3, 2);
    }

    &:nth-child(15n + 7 of .tile) {
      @include column(lg, 3, 5);
    }

    &:nth-child(15n + 8 of .tile) {
      @include column(lg, 3, 9);
    }

    &:nth-child(15n + 9 of .tile) {
      @include column(lg, 4);
    }

    &:nth-child(15n + 10 of .tile) {
      @include column(lg, 3, 5);
    }

    &:nth-child(15n + 11 of .tile) {
      @include column(lg, 3, 8);

      .hover-image {
        transform-origin: 52% 50%;
      }
    }

    &:nth-child(15n + 12 of .tile) {
      @include column(lg, 3);
    }

    &:nth-child(15n + 13 of .tile) {
      @include column(lg, 4, 8);
    }

    &:nth-child(15n + 14 of .tile) {
      @include column(lg, 3, 2);
    }

    &:nth-child(15n + 15 of .tile) {
      @include column(lg, 3, 5);
    }
  }
}

@media (any-hover: hover) {
  .tile:hover .hover-image {
    pointer-events: all;
    opacity: 1;
  }
}

.hover-image {
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity $transition;
  transform: scale(1.74);

  .tile:focus-visible & {
    @include focus-outline;
  }
}
</style>
